"use client";

// Core
import type { ReactNode } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

// Components
import { Layout } from "@/client/components/ui/Layout";
import { LayoutContainer } from "@/client/components/layouts/layout-container";
import { Row } from "@/client/components/ui/Row";
import { Col } from "@/client/components/ui/Col";
import { Logo, LogoProps } from "@/client/components/media/logo";

// Styles
import st from "./styles.module.css";

// Utils
import { ROW_GUTTER_TYPE } from "@/client/components/ui/Row/utils";
import { SCROLL_NAMES } from "@/client/utils/scroll-to";

export type LayoutAppHeader3ColsPropsType = {
  main?: ReactNode;
  userMenu?: ReactNode;
  menu?: ReactNode;
  ghost?: boolean;
  light?: boolean;
};

export const LayoutAppHeader3Cols = ({
  main = null,
  userMenu = null,
  menu,
  ghost = false,
  light = false,
}: LayoutAppHeader3ColsPropsType) => {
  const { t } = useTranslation("common");
  const layoutHeaderStyles = cx(st.header, {
    [st.ghost]: ghost,
    [st.light]: light,
  });

  const logoLightProps = { type: "dark", width: 200, height: 20 };
  const logoProps = {
    alt: t("logoAlt"),
    ...(light && logoLightProps),
  } as LogoProps;

  return (
    <Layout.Header className={layoutHeaderStyles} data-testid="layout-app-header">
      <Element name={SCROLL_NAMES.header} />
      {/* Header banner */}
      <LayoutContainer testId="layout-app-header-top">
        <div className={st.header__main}>
          <Row gutter={ROW_GUTTER_TYPE.md.horizontal} align="middle">
            <Col span={6} data-testid="header-top-logo">
              <Logo {...logoProps} />
            </Col>
            <Col span={12} className={st["header-top-main"]} data-testid="header-top-main">
              {main}
            </Col>
            <Col span={6} className={st["header-top-user-menu"]} data-testid="header-top-user-menu">
              {userMenu}
            </Col>
          </Row>
        </div>
        {menu && (
          <div className={st["header-menu"]} data-testid="layout-app-header-menu">
            {menu}
          </div>
        )}
      </LayoutContainer>
    </Layout.Header>
  );
};
